import React from 'react';
import { Link } from 'gatsby';

import { Grid } from 'components/Grid';
import { GridItem } from 'components/GridItem';

import * as styles from './index.module.scss';

export const Footer: React.FC = () => (
  <footer className={styles.footer}>
    <Grid>
      <GridItem className={styles.menu} colS="13" colM="12" colL="12">
        <Link className={styles.link} to="/">
          Home
        </Link>
        <Link className={styles.link} to="/about">
          About
        </Link>
      </GridItem>

      <GridItem colS="13" colM="22" colL="23">
        <a
          className={styles.link}
          href="https://docs.google.com/forms/d/e/1FAIpQLSfOZyRxqtPJUOevq0HtARZ9WdM3iQq0B6SptNGrs9zswWhqMw/viewform"
          target="_blank"
        >
          Suggest a Studio
        </a>
      </GridItem>

      <GridItem className={styles.signature} colS="13" colM="22" colL="34">
        Made by Invisible Guys
      </GridItem>
    </Grid>
  </footer>
);
