import { graphql, useStaticQuery } from 'gatsby';

import { Studio } from 'types';

export const useSiteData = () => {
  const {
    allStudio: { nodes },
  }: {
    allStudio: { nodes: Studio[] };
  } = useStaticQuery(graphql`
    query MyQuery {
      allStudio {
        nodes {
          abbr
          country
          field
          flag
          href
          name
        }
      }
    }
  `);

  return {
    isLoading: false,
    studios: nodes,
  };
};
